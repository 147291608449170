import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { QTable } from '../../../../Q-Components/QTable';
import { QTableBody } from '../../../../Q-Components/QTableBody';
import { QTypography } from '../../../../Q-Components/QTypography';
import AsyncTablePagination from '../../../../AsyncTablePagination';

import { LocationEvidence } from './LocationEvidence';

export function SarifEvidence({ evidences, description }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '100' },
  ];
  const [currentEvidences, setCurrentEvidences] = useState(
    evidences.length > pageSize.value
      ? evidences.slice(0, pageSize.value)
      : evidences,
  );

  // Sort the evidences by score and then by confidence
  const sortEvidences = evidences =>
    _.sortBy(evidences, [
      evidence => -evidence.properties?.score || 0,
      evidence => {
        const confidenceOrder = { high: 3, medium: 2, low: 1 };
        return confidenceOrder[evidence.properties?.confidence || 'low'];
      },
    ]);

  useEffect(() => {
    const sortedEvidences = sortEvidences(evidences);
    setCurrentEvidences(
      pageSize.value > 0
        ? sortedEvidences.slice(
            (page - 1) * pageSize.value,
            (page - 1) * pageSize.value + pageSize.value,
          )
        : sortedEvidences,
    );
  }, [page, pageSize, evidences, description]);

  const handleChangePage = (newPage, event) => {
    event.preventDefault();
    setPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = option => {
    setPageSize(option);
    setPage(1);
  };

  return (
    <>
      {evidences.length > pageSize.value && (
        <AsyncTablePagination
          pagination={{
            lastPage: Math.ceil(evidences.length / pageSize.value),
            totalData: evidences.length,
            dataPerPage: pageSize.value,
          }}
          getPage={handleChangePage}
          page={page}
          showRowsPerPage
          currentRows={currentEvidences.length}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      )}

      <QTable>
        <QTableBody>
          {evidences.length > 0 &&
            currentEvidences.map(evidence => (
              <>
                {evidence.message?.text &&
                  evidence.message.text !== description && (
                    <div style={{ marginTop: '10px' }}>
                      <b>
                        <QTypography variant="bodyRegular">
                          {evidence.message.text}
                        </QTypography>
                      </b>
                    </div>
                  )}

                {evidence.locations &&
                  evidence.locations.map(location => (
                    <LocationEvidence
                      key={location.id}
                      location={location}
                      codeFlows={evidence.codeFlows}
                      stacks={evidence.stacks}
                      evidences={evidence}
                    />
                  ))}
              </>
            ))}
        </QTableBody>
      </QTable>

      {evidences.length > pageSize.value && (
        <div style={{ marginTop: '10px' }}>
          <AsyncTablePagination
            pagination={{
              lastPage: Math.ceil(evidences.length / pageSize.value),
              totalData: evidences.length,
              dataPerPage: pageSize.value,
            }}
            getPage={handleChangePage}
            page={page}
            showRowsPerPage
            currentRows={currentEvidences.length}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      )}
    </>
  );
}
