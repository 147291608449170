import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { QTable } from '../../../../Q-Components/QTable';
import { QTableBody } from '../../../../Q-Components/QTableBody';
import { QIconButton } from '../../../../Q-Components/QIconButton';
import { QModal } from '../../../../Q-Components/QModal';
import { QModalTitle } from '../../../../Q-Components/QModalTitle';
import { QModalContent } from '../../../../Q-Components/QModalContent';
import { QTypography } from '../../../../Q-Components/QTypography';

import { LocationEvidence } from './LocationEvidence';

export function CodeFlowsEvidenceModal(props) {
  const { mastV2 } = useFlags();
  const { isOpen, toggle, codeFlows, startLocation } = props;

  return mastV2 ? (
    <Dialog open={isOpen} toggle={toggle} maxWidth="xl">
      <DialogTitle sx={{ padding: '0px' }}>
        <QIconButton
          onClick={toggle}
          style={{ float: 'right', color: '#000000', margin: '12px' }}
        >
          <CloseIcon />
        </QIconButton>
      </DialogTitle>
      <DialogContent>
        <QTable>
          <QTableBody>
            {codeFlows.map((codeFlow, codeFlowIndex) => (
              <div style={codeFlowIndex > 0 ? { paddingTop: '12px' } : {}}>
                <QTypography variant="h5Medium">{startLocation}</QTypography>
                <div style={{ paddingLeft: '8px' }}>
                  {codeFlow.threadFlows?.map(threadFlow =>
                    threadFlow.locations?.map((codeFlowLocation, index) => (
                      <LocationEvidence
                        evidences
                        location={codeFlowLocation.location}
                        step={index + 1}
                      />
                    )),
                  )}
                </div>
              </div>
            ))}
          </QTableBody>
        </QTable>
      </DialogContent>
    </Dialog>
  ) : (
    <QModal isOpen={isOpen} toggle={toggle} size="lg">
      <QModalTitle toggle={toggle}>Code Flows</QModalTitle>
      <QModalContent>
        <QTable>
          <QTableBody>
            {codeFlows.map((codeFlow, codeFlowIndex) => (
              <div style={codeFlowIndex > 0 ? { paddingTop: '12px' } : {}}>
                <div style={{ fontSize: '18px', fontWeight: 600 }}>
                  {startLocation}
                </div>
                <div style={{ paddingLeft: '8px' }}>
                  {codeFlow.threadFlows?.map(threadFlow =>
                    threadFlow.locations?.map((codeFlowLocation, index) => (
                      <LocationEvidence
                        evidences
                        location={codeFlowLocation.location}
                        step={index + 1}
                      />
                    )),
                  )}
                </div>
              </div>
            ))}
          </QTableBody>
        </QTable>
      </QModalContent>
    </QModal>
  );
}
